const qs = require('querystring');

const baseAjaxConfig = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  host: process.env.GATSBY_API_BASE
};

const setAuthHeader = () => {
  if (localStorage) {
    const token = localStorage.getItem('accessToken');
    // baseAjaxConfig.headers['access-token'] = token;
    baseAjaxConfig.headers['Authorization'] = `Bearer ${token}`;
  }
};

const utils = {
    getReadDurationMap: (data) => {
        let shReadDurationMap = {};
        data.forEach((item) => {
            item && item.values ?
              shReadDurationMap[item.values.slug] = Math.ceil(JSON.stringify(Object.values(item.values.acf_super_honestly)).length / 3000)
            : shReadDurationMap[item.slug] = Math.ceil(JSON.stringify(Object.values(item.acf_super_honestly)).length / 3000);
        });
        return shReadDurationMap;
    },
    emailValidator: (email) => {
        const pattern = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i);
        return pattern.test(email);
    },
    passwordValidator: (password)=> {
        const pattern = new RegExp(/^(?=.*[a-z]+)(?=.*[A-Z]+)(?=.*[0-9]+)(?=.*[!@#$%^&*]).{8,}$/);
        return pattern.test(password);
    },
    textValidator: (text) => {
        const pattern = new RegExp(/^[A-Za-z0-9.,\s_-]+$/g);
        return pattern.test(text)
    },
    getPlainText: (data) => {
        return data.replace(/\[(([^\]\[]+)|(.+))\]\[(([^\]\[]+)|(.+))\]/g, '');
    },
    getYoutubeVideoID: (url) => {
        url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
        return (url[2] !== undefined) ? url[2].split(/[^0-9a-z_-]/i)[0] : url[0];
    },
    api: {
      get: (path, data) => {
        setAuthHeader();
        return fetch(baseAjaxConfig.host + path + ("?" + qs.stringify(data)), {
          method: "GET",
          headers: baseAjaxConfig.headers
        }).then(response => {
          if (response.ok) {
            return response.json();
          } else {
            if (response.status === 401) {
              return response.json();
            }
            let err = new Error('API Error. Failed to fetch');
            return Promise.reject(err);
          }
        }).then(json => {
          return json;
        }, err => console.log(err));
      },
      post: (path, data) => {
        setAuthHeader();
        return fetch(baseAjaxConfig.host + path, {
          method: "POST",
          headers: baseAjaxConfig.headers,
          body: JSON.stringify(data)
        }).then(response => {
          if (response.ok) {
            return response.json();
          } else { 
            let err = new Error('API Error. Failed to send data.');
            return Promise.reject(err);
          }
        }).then(json => {
          return json;
        }, err => console.log(err));
      },
    },
    getPostQuery: (id, type, slug) => {
        switch(type) {
            case 'definition' :
              return `{
                definition(id: ${id}, idType: DATABASE_ID) {
                  title
                  slug
                  acf_definition {
                    description
                  }
                }
              }`;
            case 'wise-tip' :
                return `{
                    wiseTip(id: ${id}, idType: DATABASE_ID) {
                        title
                        slug
                        databaseId
                        status
                        acf_wise_tip {
                            type
                            themeColor
                            tip
                            description
                            enableSurvey
                        }
                        ageStages {
                            nodes {
                                slug
                                name
                                description
                            }
                        }
                        domains {
                            nodes {
                                slug
                                name
                                parentDatabaseId
                            }
                        }
                        contexts {
                            nodes {
                                slug
                                name
                            }
                        }
                    }
                }`;
            case 'wise-self' :
                return `{
                    page(id: "wise-journey", idType: URI) {
                      acf_wise_journey_page {
                        groupJourney
                        onlineJourney
                      }
                    }
                    wiseSelfs(first: 100) {
                        nodes {
                            acf_wise_self {
                                date
                                description
                                themeColor
                                enableSurvey
                                sessions {
                                sessionDetail
                                title
                                }
                            }
                            slug
                            status
                            title
                            ageStages {
                                nodes {
                                slug
                                name
                                description
                                }
                            }
                        }
                    }
                }`;
            case 'wise-speak' :
                return `{
                    page(id: "wise-journey", idType: URI) {
                      acf_wise_journey_page {
                        groupJourney
                        onlineJourney
                      }
                    }
                    wiseSpeaks(first: 100) {
                        nodes {
                            acf_wise_speak {
                                date
                                description
                                themeColor
                                enableSurvey
                                sessions {
                                sessionDetail
                                title
                                }
                            }
                            slug
                            status
                            title
                            ageStages {
                                nodes {
                                slug
                                name
                                description
                                }
                            }
                        }
                    }
                }`;
            case 'super-honestly' :
                return `{
                    superHonestly(id: ${id}, idType: DATABASE_ID) {
                        tags {
                          nodes {
                            name
                            slug
                          }
                        }
                        acf_super_honestly {
                            author
                            fieldGroupName
                            description
                            flexibleContent {
                                ... on SuperHonestly_AcfSuperHonestly_FlexibleContent_Paragraph {
                                    content
                                    fieldGroupName
                                }
                                ... on SuperHonestly_AcfSuperHonestly_FlexibleContent_Quote {
                                    author
                                    fieldGroupName
                                    quoteText
                                }
                                ... on SuperHonestly_AcfSuperHonestly_FlexibleContent_VideoBlock {
                                  fieldGroupName
                                  videoId
                                  vType
                                  thumbnail {
                                    sourceUrl
                                  }
                                }
                                ... on SuperHonestly_AcfSuperHonestly_FlexibleContent_WiseTip {
                                    fieldGroupName
                                    customTip
                                    wiseTipCard {
                                        ... on WiseTip {
                                            acf_wise_tip {
                                                type
                                                tip
                                                themeColor
                                            }
                                            ageStages {
                                                nodes {
                                                    slug
                                                    name
                                                    description
                                                }
                                            }
                                            domains {
                                                nodes {
                                                    slug
                                                    name
                                                    parentDatabaseId
                                                }
                                            }
                                            contexts {
                                                nodes {
                                                    slug
                                                    name
                                                }
                                            }
                                            id
                                            slug
                                            title
                                            status
                                        }
                                    }
                                }
                                ... on SuperHonestly_AcfSuperHonestly_FlexibleContent_SuperHonestly {
                                    fieldGroupName
                                    superHonestlyCard {
                                        ... on SuperHonestly {
                                            slug
                                            status
                                            title
                                            acf_super_honestly {
                                                thumbnail {
                                                    sourceUrl
                                                    title
                                                }
                                                description
                                            }
                                        }
                                    }
                                }
                                ... on SuperHonestly_AcfSuperHonestly_FlexibleContent_WiseSpeak {
                                    fieldGroupName
                                    wiseSpeakCard {
                                      ... on WiseSpeak {
                                        acf_wise_speak {
                                          date
                                          description
                                          fieldGroupName
                                        }
                                        slug
                                        status
                                        title
                                      }
                                    }
                                }
                                ... on SuperHonestly_AcfSuperHonestly_FlexibleContent_WiseSelf {
                                    fieldGroupName
                                    wiseSelfCard {
                                      ... on WiseSelf {
                                        acf_wise_self {
                                          date
                                          description
                                          fieldGroupName
                                        }
                                        slug
                                        status
                                        title
                                      }
                                    }
                                }
                                ... on SuperHonestly_AcfSuperHonestly_FlexibleContent_Image {
                                    fieldGroupName
                                    imageData {
                                        sourceUrl
                                        title
                                    }
                                    picCredit
                                }
                            }
                            bannerImage {
                                image {
                                    sourceUrl
                                    title
                                }
                                picCredit
                            }
                            thumbnail {
                                sourceUrl
                                title
                            }
                            relatedStories {
                                ... on SuperHonestly {
                                    acf_super_honestly {
                                        thumbnail {
                                            sourceUrl
                                        }
                                    }
                                    slug
                                    status
                                    title
                                }
                            }
                        }
                        databaseId
                        title
                        status
                        slug
                        nextSuperHonestly {
                            node {
                            slug
                            status
                            title
                            }
                        }
                        previousSuperHonestly {
                            node {
                            slug
                            status
                            title
                            }
                        }
                    }
                }`;

            case 'page' :
                if (slug === 'home-page') {
                    return `{
                        page(id: "home-page", idType: URI) {
                            title
                            slug
                            acf_home_page {
                                videoBlock {
                                    title
                                    videoId
                                    thumbnail {
                                        altText
                                        sourceUrl
                                    }
                                }
                                weekTips {
                                    ... on WiseTip {
                                        title
                                        slug
                                        status
                                        acf_wise_tip {
                                            type
                                            tip
                                            themeColor
                                            description
                                            fieldGroupName
                                        }
                                        ageStages {
                                            nodes {
                                                id
                                                slug
                                                name
                                                parentId
                                                description
                                            }
                                        }
                                        domains {
                                            nodes {
                                                id
                                                slug
                                                name
                                                parentDatabaseId
                                                description
                                            }
                                        }
                                        contexts {
                                            nodes {
                                                id
                                                name
                                                slug
                                                parentId
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }`;
                } if (slug === 'super-honestly') {
                    return `{
                        page(id: "super-honestly", idType: URI) {
                            acf_super_honestly_list {
                                topCard {
                                    ... on SuperHonestly {
                                        acf_super_honestly {
                                            thumbnail {
                                                sourceUrl
                                            }
                                            description
                                        }
                                        title
                                        slug
                                        status
                                    }
                                }
                            }
                        }
                        superHonestlys(first: 10) {
                            nodes {
                                tags {
                                  nodes {
                                    slug
                                    name
                                  }
                                }
                                acf_super_honestly {
                                    author
                                    fieldGroupName
                                    description
                                    relatedStories {
                                        ... on SuperHonestly {
                                          acf_super_honestly {
                                            thumbnail {
                                              sourceUrl
                                            }
                                          }
                                          contexts {
                                              nodes {
                                                  slug
                                                  name
                                              }
                                          }
                                          id
                                          slug
                                          title
                                          status
                                      }
                                  }
                                }
                                ... on SuperHonestly_AcfSuperHonestly_FlexibleContent_SuperHonestly {
                                  fieldGroupName
                                  superHonestlyCard {
                                    ... on SuperHonestly {
                                      slug
                                      status
                                      title
                                      acf_super_honestly {
                                          thumbnail {
                                              sourceUrl
                                              title
                                          }
                                          description
                                      }
                                    }
                                  }
                                }
                                ... on SuperHonestly_AcfSuperHonestly_FlexibleContent_WiseSpeak {
                                  fieldGroupName
                                  wiseSpeakCard {
                                    ... on WiseSpeak {
                                      acf_wise_speak {
                                        date
                                        description
                                        fieldGroupName
                                      }
                                      slug
                                      status
                                      title
                                    }
                                  }
                                }
                                ... on SuperHonestly_AcfSuperHonestly_FlexibleContent_WiseSelf {
                                  fieldGroupName
                                  wiseSelfCard {
                                    ... on WiseSelf {
                                      acf_wise_self {
                                        date
                                        description
                                        fieldGroupName
                                      }
                                      slug
                                      status
                                      title
                                    }
                                  }
                                }
                                ... on SuperHonestly_AcfSuperHonestly_FlexibleContent_Image {
                                  fieldGroupName
                                  imageData {
                                    sourceUrl
                                    title
                                  }
                                  picCredit
                                }
                              }
                              bannerImage {
                                  image {
                                      sourceUrl
                                      title
                                  }
                                  picCredit
                              }
                              thumbnail {
                                sourceUrl
                                title
                              }
                            }
                            databaseId
                            title
                            status
                            slug
                            nextSuperHonestly {
                              node {
                                slug
                                status
                                title
                              }
                            }
                            previousSuperHonestly {
                              node {
                                slug
                                status
                                title
                              }
                            }
                          }
                        }
                    }`
                } if (slug === 'wise-journey') {
                    return `{
                        page(id: "wise-journey", idType: URI) {
                            acf_wise_journey_page {
                              groupJourney
                              onlineJourney
                              bannerImage {
                                image {
                                  sourceUrl
                                }
                              }
                              title
                              heading
                              contents {
                                flexibleContent {
                                  ... on Page_AcfWiseJourneyPage_Contents_FlexibleContent_Paragraph {
                                    content
                                    fieldGroupName
                                  }
                                  ... on Page_AcfWiseJourneyPage_Contents_FlexibleContent_Quote {
                                    author
                                    fieldGroupName
                                    quoteText
                                  }
                                  ... on Page_AcfWiseJourneyPage_Contents_FlexibleContent_Image {
                                    fieldGroupName
                                    imageData {
                                      sourceUrl
                                      title
                                    }
                                    picCredit
                                  }
                                  ... on Page_AcfWiseJourneyPage_Contents_FlexibleContent_WiseTip {
                                    fieldGroupName
                                    customTip
                                    wiseTipCard {
                                        ... on WiseTip {
                                            acf_wise_tip {
                                                type
                                                tip
                                                themeColor
                                            }
                                            ageStages {
                                                nodes {
                                                    slug
                                                    name
                                                    description
                                                }
                                            }
                                            domains {
                                                nodes {
                                                    slug
                                                    name
                                                    parentDatabaseId
                                                }
                                            }
                                            contexts {
                                                nodes {
                                                    slug
                                                    name
                                                }
                                            }
                                            id
                                            slug
                                            title
                                            status
                                        }
                                    }
                                  }
                                  ... on Page_AcfWiseJourneyPage_Contents_FlexibleContent_SuperHonestly {
                                    fieldGroupName
                                    superHonestlyCard {
                                      ... on SuperHonestly {
                                        slug
                                        status
                                        title
                                        acf_super_honestly {
                                            thumbnail {
                                                sourceUrl
                                                title
                                            }
                                            description
                                        }
                                      }
                                    }
                                  }
                                  ... on Page_AcfWiseJourneyPage_Contents_FlexibleContent_WiseSelf {
                                    fieldGroupName
                                    wiseSelfCard {
                                      ... on WiseSelf {
                                        title
                                        slug
                                        status
                                        ageStages {
                                          nodes {
                                            slug
                                            name
                                            description
                                          }
                                        }
                                        acf_wise_self {
                                          date
                                          themeColor
                                          mediaType
                                          enableSurvey
                                          image {
                                            sourceUrl
                                          }
                                          video {
                                            videoId
                                            thumbnail {
                                              sourceUrl
                                            }
                                          }
                                          description
                                        }
                                      }
                                    }
                                  }
                                  ... on Page_AcfWiseJourneyPage_Contents_FlexibleContent_VideoBlock {
                                    fieldGroupName
                                    videoId
                                    vType
                                    thumbnail {
                                      sourceUrl
                                    }
                                  }
                                  ... on Page_AcfWiseJourneyPage_Contents_FlexibleContent_WiseSpeak {
                                    fieldGroupName
                                    wiseSpeakCard {
                                      ... on WiseSpeak {
                                        title
                                        slug
                                        status
                                        ageStages {
                                          nodes {
                                            slug
                                            name
                                            description
                                          }
                                        }
                                        acf_wise_speak {
                                          date
                                          themeColor
                                          mediaType
                                          enableSurvey
                                          image {
                                            sourceUrl
                                          }
                                          video {
                                            videoId
                                            thumbnail {
                                              sourceUrl
                                            }
                                          }
                                          description
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                              wiseSelf {
                                flexibleContent {
                                  ... on Page_AcfWiseJourneyPage_WiseSelf_FlexibleContent_Paragraph {
                                    fieldGroupName
                                    content
                                  }
                                  ... on Page_AcfWiseJourneyPage_WiseSelf_FlexibleContent_Quote {
                                    fieldGroupName
                                    quoteText
                                    author
                                  }
                                  ... on Page_AcfWiseJourneyPage_WiseSelf_FlexibleContent_Image {
                                    fieldGroupName
                                    imageData {
                                      sourceUrl
                                      title
                                    }
                                    picCredit
                                  }
                                  ... on Page_AcfWiseJourneyPage_WiseSelf_FlexibleContent_WiseTip {
                                    fieldGroupName
                                    customTip
                                    wiseTipCard {
                                        ... on WiseTip {
                                            acf_wise_tip {
                                                type
                                                tip
                                                themeColor
                                            }
                                            ageStages {
                                                nodes {
                                                    slug
                                                    name
                                                    description
                                                }
                                            }
                                            domains {
                                                nodes {
                                                    slug
                                                    name
                                                    parentDatabaseId
                                                }
                                            }
                                            contexts {
                                                nodes {
                                                    slug
                                                    name
                                                }
                                            }
                                            id
                                            slug
                                            title
                                            status
                                        }
                                    }
                                  }
                                  ... on Page_AcfWiseJourneyPage_WiseSelf_FlexibleContent_SuperHonestly {
                                    fieldGroupName
                                    superHonestlyCard {
                                      ... on SuperHonestly {
                                        slug
                                        status
                                        title
                                        acf_super_honestly {
                                            thumbnail {
                                                sourceUrl
                                                title
                                            }
                                            description
                                        }
                                      }
                                    }
                                  }
                                  ... on Page_AcfWiseJourneyPage_WiseSelf_FlexibleContent_WiseSelf {
                                    fieldGroupName
                                    wiseSelfCard {
                                      ... on WiseSelf {
                                        title
                                        slug
                                        status
                                        ageStages {
                                          nodes {
                                            slug
                                            name
                                            description
                                          }
                                        }
                                        acf_wise_self {
                                          date
                                          themeColor
                                          mediaType
                                          enableSurvey
                                          image {
                                            sourceUrl
                                          }
                                          video {
                                            videoId
                                            thumbnail {
                                              sourceUrl
                                            }
                                          }
                                          description
                                        }
                                      }
                                    }
                                  }
                                  ... on Page_AcfWiseJourneyPage_WiseSelf_FlexibleContent_VideoBlock {
                                    fieldGroupName
                                    videoId
                                    vType
                                    thumbnail {
                                      sourceUrl
                                    }
                                  }
                                  ... on Page_AcfWiseJourneyPage_WiseSelf_FlexibleContent_WiseSpeak {
                                    fieldGroupName
                                    wiseSpeakCard {
                                      ... on WiseSpeak {
                                        title
                                        slug
                                        status
                                        ageStages {
                                          nodes {
                                            slug
                                            name
                                            description
                                          }
                                        }
                                        acf_wise_speak {
                                          date
                                          themeColor
                                          mediaType
                                          enableSurvey
                                          image {
                                            sourceUrl
                                          }
                                          video {
                                            videoId
                                            thumbnail {
                                              sourceUrl
                                            }
                                          }
                                          description
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                              wiseSpeak {
                                flexibleContent {
                                  ... on Page_AcfWiseJourneyPage_WiseSpeak_FlexibleContent_Paragraph {
                                    fieldGroupName
                                    content
                                  }
                                  ... on Page_AcfWiseJourneyPage_WiseSpeak_FlexibleContent_Quote {
                                    fieldGroupName
                                    author
                                    quoteText
                                  }
                                  ... on Page_AcfWiseJourneyPage_WiseSpeak_FlexibleContent_Image {
                                    fieldGroupName
                                    imageData {
                                      sourceUrl
                                      title
                                    }
                                    picCredit
                                  }
                                  ... on Page_AcfWiseJourneyPage_WiseSpeak_FlexibleContent_WiseTip {
                                    fieldGroupName
                                    customTip
                                    wiseTipCard {
                                        ... on WiseTip {
                                            acf_wise_tip {
                                                type
                                                tip
                                                themeColor
                                            }
                                            ageStages {
                                                nodes {
                                                    slug
                                                    name
                                                    description
                                                }
                                            }
                                            domains {
                                                nodes {
                                                    slug
                                                    name
                                                    parentDatabaseId
                                                }
                                            }
                                            contexts {
                                                nodes {
                                                    slug
                                                    name
                                                }
                                            }
                                            id
                                            slug
                                            title
                                            status
                                        }
                                    }
                                  }
                                  ... on Page_AcfWiseJourneyPage_WiseSpeak_FlexibleContent_SuperHonestly {
                                    fieldGroupName
                                    superHonestlyCard {
                                      ... on SuperHonestly {
                                        slug
                                        status
                                        title
                                        acf_super_honestly {
                                            thumbnail {
                                                sourceUrl
                                                title
                                            }
                                            description
                                        }
                                      }
                                    }
                                  }
                                  ... on Page_AcfWiseJourneyPage_WiseSpeak_FlexibleContent_WiseSelf {
                                    fieldGroupName
                                    wiseSelfCard {
                                      ... on WiseSelf {
                                        title
                                        slug
                                        ageStages {
                                          nodes {
                                            slug
                                            name
                                            description
                                          }
                                        }
                                        acf_wise_self {
                                          date
                                          themeColor
                                          mediaType
                                          enableSurvey
                                          image {
                                            sourceUrl
                                          }
                                          video {
                                            videoId
                                            thumbnail {
                                              sourceUrl
                                            }
                                          }
                                          description
                                        }
                                      }
                                    }
                                  }
                                  ... on Page_AcfWiseJourneyPage_WiseSpeak_FlexibleContent_VideoBlock {
                                    fieldGroupName
                                    videoId
                                    vType
                                    thumbnail {
                                      sourceUrl
                                    }
                                  }
                                  ... on Page_AcfWiseJourneyPage_WiseSpeak_FlexibleContent_WiseSpeak {
                                    fieldGroupName
                                    wiseSpeakCard {
                                      ... on WiseSpeak {
                                        title
                                        slug
                                        ageStages {
                                          nodes {
                                            slug
                                            name
                                            description
                                          }
                                        }
                                        acf_wise_speak {
                                          date
                                          themeColor
                                          mediaType
                                          enableSurvey
                                          image {
                                            sourceUrl
                                          }
                                          video {
                                            videoId
                                            thumbnail {
                                              sourceUrl
                                            }
                                          }
                                          description
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                        }
                    }`
                } else if (slug === 'privacy-policy' || slug === 'terms-and-conditions') {
                    return `{
                        page(id: "${slug}", idType: URI) {
                            page_content {
                                content
                            }
                        }
                    }`;
                }

            default: return null
        }
    }
};

module.exports = utils;
