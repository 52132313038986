import React, { useState, useEffect } from "react"
import { api } from '../../utils';

const UserContext = React.createContext()

const UserProvider = (props) => {
    const { children } = props;
    const [user, setUser] = useState();
    const [isFetching, setIsFetching] = useState(false);

    useEffect(() => {
        getCurrentUser();
    }, []);

    const getCurrentUser = () => {
        setIsFetching(true);
        api.get('/api/v1/auth/user')
        .then(resp => {
            setIsFetching(false);
            if (resp && resp.success && resp.data) {
                setUser(resp.data);
            }
        });
    }

    if (isFetching) {
        return (
            <div className="w3-center w3-padding-32" style={{ minHeight: '100vH' }}>
                <div className="pw-loader" />
            </div>
        )
    }

    const setUserData = (user) => {
        setUser(user);
    }

    return (
      <UserContext.Provider
        value={{ user, setUserData }}
      >
        {children}
      </UserContext.Provider>
    )
}

export default UserContext;

export { UserProvider }
